import React from "react"
import { Link } from "gatsby"

import SEO from "../components/SEOadvanced"
import Navigation from "../components/Navigation"
import Footer from "../components/Footer"

import Container from "react-bootstrap/Container"

const LegalPage = () => (
  <>
    <SEO
      title="Правила користування Б'юті-майстра"
      description="Дані правила поширюються на всіх відвідувачів і користувачів сайту olyakoval.in.ua."
    />
    <header>
      <Navigation menuVariant="light" />
      <section className="py-5">
        <Container className="mb-5">
          <h1 className="mb-4">Правила користування сайтом</h1>
          <p>
            Дані правила поширюються на всіх відвідувачів і користувачів сайту
            olyakoval.in.ua. Використовуючи сайт, користувач висловлює свою
            згоду з даними правилами.
          </p>

          <h2>Загальна правова інформація</h2>
          <p>
            Дія правил користування цим сайтом поширюється як на фізичних, так і
            на юридичних осіб. Фізична особа Коваль О.П. (далі - "Власник")
            залишає за собою право змінювати інформацію, послуги та продукцію в
            будь-який час без попереднього повідомлення. Посилання на інформацію
            та послуги третіх осіб робиться в інформаційних цілях і не має на
            увазі, що Власник їх підтримує або рекомендує.
          </p>

          <h2>Захист авторських прав</h2>
          <p>
            Всі права на інформацію, розміщену на даному сайті, належать
            Власнику.
          </p>
          <p>
            Зміна матеріалів або їх використання без письмової згоди Власника є
            порушенням авторських прав.
          </p>
          <p>
            У разі порушення правил користування цим сайтом Власник залишає за
            собою право застосувати до порушників всі допустимі законом
            запобіжні заходи для захисту своїх прав.
          </p>
          <p>
            На інших сторінках цього сайту можуть міститися зауваження про права
            власності та інформація про авторські права, ці положення також
            повинні бути враховані й дотримані.
          </p>

          <h2>Обмежена ліцензія</h2>
          <p>
            Вся інформація даного сайту захищена авторськими правами. Дана
            інформація не може бути відтворена ні в якій формі, ніякими засобами
            без попередньої письмової згоди Власника. За письмовим дозволом
            звертатися за контактною інформацією, розміщеною на сторінці за
            адресою: olyakoval.in.ua/kontakty/
          </p>
          <p>
            Всі інші дії будуть витлумачені як непряме, навмисне або будь-яке
            інше привласнення ліцензії або прав на патент, торгову марку або
            привласнення авторських прав Власника або третьої особи.
          </p>

          <h2>Звільнення від відповідальності</h2>
          <p>
            Тією ж мірою, в якій це дозволено законодавством, Власник сайту і
            пов'язані з ними особи не несуть відповідальність за будь-який
            збиток, що випливає прямо або побічно з використання даного сайту
            або будь-якого іншого сайту, пов'язаного з даним сайтом. Ні в якому
            разі відповідальність за наслідки, які прямо або побічно призвело за
            собою використання інформації, розміщеної на цьому Сайті, не може
            покладатися на Власника Сайту і бути підставою для їх судового
            переслідування. Власник сайту не заявляє і не гарантує, що будь-який
            файл або програма, які можна завантажити з даного сайту і/або
            використовувати з його допомогою, вільних від помилок, вірусів,
            дефектів та інших чинників, які можуть завдати шкоди даними, а також
            апаратного і програмного забезпечення користувача.
          </p>
          <p>
            Послуги Сайту надаються за принципом "як є" без гарантій будь-якого
            роду як прямих, так і непрямих. Користувач в добровільному порядку
            відмовляється від судового переслідування Власника Сайту і
            відшкодування можливої шкоди, заподіяної шкоди Користувачеві.
          </p>
          <p>
            Ні адміністрація сайту, а ні її партнери або співробітники не
            гарантують безперебійної й безпомилкової роботи Сайту; також вони не
            гарантують, що такими будуть результати, отримані в ході
            використання Сайту, так і точність і застосовність його матеріалів.
          </p>
          <p>
            Власник сайту не несе ніякої відповідальності перед Користувачем або
            третіми особами за можливий збиток, пов'язаний з використанням
            інформації або матеріалів, розміщених на даному сайті.
          </p>

          <h2>Точність інформації</h2>
          <p>
            Власник буде докладати всіх зусиль, щоб забезпечити точність і
            актуальність даних, що містяться на цьому сайті. У представленій на
            цьому сайті, можуть містити технічні неточності або друкарські
            помилки. Власник не бере на себе ніякої відповідальності за точність
            інформації, що надається на сайті, і весь ризик використання такої
            інформації покладається на користувача.
          </p>

          <h2>Посилання на сайти третіх осіб</h2>
          <p>
            Даний сайт може містити інформацію про сайти третіх осіб. Перехід на
            будь-який інший Інтернет-ресурс, пов'язаний з даним сайтом,
            здійснюється на страх і ризик користувача. Власник не несе
            відповідальності за точність інформації, даних, поглядів, порад або
            заяв, зроблених на Сайті. Посилання на продукцію та послуги третіх
            осіб робиться в інформаційних цілях і не має на увазі, що Власник їх
            підтримує або рекомендує або несе за це відповідальність.
          </p>

          <h2>Зміна умов</h2>
          <p>
            Власник сайту може вносити зміни до правила користування сайтом.
            Проглядаючи даний сайт, Ви приймаєте зобов'язання дотримуватися цих
            правил, тому Ви повинні перевіряти їх при кожному новому
            відвідуванні сайту.
          </p>

          <h2 className="uppercase">
            Згода на збiр та обробку персональних даних
          </h2>

          <p>
            Користувач Сайту приймає Угоду користувача та надає згоду на обробку
            своїх персональних даних, підтверджує, що ознайомлений з метою
            обробки персональних даних та правами, наданими згідно ст.8 Закону
            України "Про захист персональних даних".
          </p>
          <p>
            Метою обробки персональних даних користувача є забезпечення
            можливості надання послуг користувачу Власником.
          </p>
          <p>
            Обробка персональних даних — будь-яка дія або сукупність дій, таких
            як збирання, реєстрація, накопичення, зберігання, адаптування,
            зміна, поновлення, використання і поширення (розповсюдження,
            реалізація, передача), знеособлення, знищення персональних даних, у
            тому числі з використанням інформаційних (автоматизованих) систем
            (ст. 2 Закону України «Про захист персональних даних» від 01.06.2010
            року № 2297-УІ).
          </p>

          <p>
            Права суб’єкта персональних даних відповідно ст.8 до Закону України
            «Про захист персональних даних» від 01.06.2010 року № 2297-УІ
          </p>

          <p>
            Особисті немайнові права на персональні дані, які має кожна фізична
            особа, є невід'ємними й непорушними.
          </p>
          <h3>Суб'єкт персональних даних має право:</h3>
          <ol>
            <li>
              знати про джерела збирання, місцеперебування своїх персональних
              даних, мету їх обробки, місцеперебування або місце проживання
              (перебування) власника чи розпорядника персональних даних або дати
              відповідне доручення щодо отримання цієї інформації уповноваженим
              ним особам, крім випадків, встановлених законом;
            </li>
            <li>
              отримувати інформацію про умови надання доступу до персональних
              даних, зокрема інформацію про третіх осіб, яким передаються його
              персональні дані;
            </li>
            <li>на доступ до своїх персональних даних;</li>
            <li>
              отримувати не пізніш як за тридцять календарних днів з дня
              надходження запиту, крім випадків, передбачених законом, відповідь
              про те, чи обробляються його персональні дані, а також отримувати
              зміст таких персональних даних;
            </li>
            <li>
              пред'являти вмотивовану вимогу власнику персональних даних із
              запереченням проти обробки своїх персональних даних;
            </li>
            <li>
              пред'являти вмотивовану вимогу щодо зміни або знищення своїх
              персональних даних будь-яким власником та розпорядником
              персональних даних, якщо ці дані обробляються незаконно чи є
              неправдивими;
            </li>
            <li>
              на захист своїх персональних даних від незаконної обробки та
              випадкової втрати, знищення, пошкодження у зв'язку з умисним
              приховуванням, ненаданням чи несвоєчасним їх наданням, а також на
              захист від надання відомостей, що є неправдивими чи ганьблять
              честь, гідність та ділову репутацію фізичної особи;
            </li>
            <li>
              звертатися зі скаргами на обробку своїх персональних даних до
              Власника або до суду;
            </li>
            <li>
              застосовувати засоби правового захисту в разі порушення
              законодавства про захист персональних даних;
            </li>
            <li>
              вносити застереження стосовно обмеження права на обробку своїх
              персональних даних під час надання згоди;
            </li>
            <li>відкликати згоду на обробку персональних даних;</li>
            <li>знати механізм автоматичної обробки персональних даних;</li>
            <li>
              на захист від автоматизованого рішення, яке має для нього правові
              наслідки.
            </li>
          </ol>
          <Link to="/" className="btn btn-danger">
            Перейти на головну сторінку
          </Link>
        </Container>
      </section>
    </header>
    <Footer />
  </>
)

export default LegalPage
